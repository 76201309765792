<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Edit'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-services' }">{{
            $t("MENU.ITEM.SRV.SERVICES")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:body>
        <b-form>
          <FormBuilder
            v-if="!firstLoader"
            :form="form"
            :schemaJson="schemaJson"
            :v="$v"
          ></FormBuilder>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            with-copy
            :defaultAction="'continue'"
            :isLoading="isLoadingService"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { FETCH_MEASURES } from "@/modules/measures/store/measures.module";
import {
  FETCH_SERVICE,
  UPDATE_SERVICE,
} from "@/modules/services/store/service.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";

import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      id: null,
      form: {},
      measures: [],
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown,
    FormInput,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.SRV.SERVICES"),
        route: { name: "list-services" },
      },
      { title: this.$i18n.t("MENU.ITEM.EDIT") },
    ]);

    this.$store.dispatch(FETCH_MEASURES).then((data) => {
      this.measures = data.data.items;
    });

    let id = this.$route.params.id;
    this.$store.dispatch(FETCH_SERVICE, id).then((data) => {
      this.id = data.data.id;
      this.generateFormOutOfSchemaJson(this.schemaJson, data.data);
    });
  },
  computed: {
    ...mapGetters(["isLoadingService", "isLoadingSharedStore"]),
    ...mapState({
      errors: (state) => state.errors,
    }),

    schemaJson() {
      return {
        fields: [
          {
            type: "input",
            inputType: "text",
            id: "provider-name-input",
            groupId: "provider-name-group",
            required: "required",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "FORM_LABELS.SERVICE_NAME",
              placeholder: "FORM_LABELS.SERVICE_NAME_PLACEHOLDER",
              validation: "FORM.VALID_NAME",
            },
            validations: {
              required: true,
              minLength: 2,
            },
            translatable: false,
            model: "name",
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-measure-input",
            feedback_id: "measureId",
            colSm: "6",
            divider: false,
            clearable: false,
            i18n: {
              label: "FORM_LABELS.MEASURE_NAME",
              placeholder: "FORM_LABELS.MEASURE_NAME",
            },
            options: this.measures,
            validations: {
              required: true,
            },
            model: "measureId",
          },
          {
            type: "switch",
            groupId: "is-unloading-group",
            id: "is-unloading-input",
            inputType: "checkbox",
            required: "required",
            feedback_id: "active-live-feedback",
            colSm: "auto",
            divider: true,
            default: false,
            i18n: {
              label: "FORM_LABELS.IS_UNLOADING",
              placeholder: "FORM_PLACEHOLDERS.IS_UNLOADING",
            },
            validations: {
              required: true,
            },
            model: "isUnloading",
          },
        ],
      };
    },
  },
  validations() {
    let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    return tmpValidationObject;
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return;
      }
      this.$store
        .dispatch(UPDATE_SERVICE, { id: this.id, payload: this.form })
        .then((data) => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });
          if (next == "new") {
            //New
            this.$router.push({ name: "add-services" });
          }
          if (next == "copy") {
            this.$router.push({
              name: "add-services",
              params: { formData: this.form },
            });
          }

          if (next == "exit") {
            this.$router.push({ name: "list-services" });
          }
        })
        .catch((response) => {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
    resetVForm() {
      this.$v.$reset();
    },
  },
};
</script>
